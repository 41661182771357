<template>
  <div class="h-100 be-row justify-content-center">
    <div class="h-100 col-xl-12 p-0">
      <transition mode="out-in" name="slide-fade">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShoppingPage",
};
</script>

<style scoped></style>
